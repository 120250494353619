const currentUrl = window.location.href.split('/');
if (currentUrl[2] == 'sdm.vertexplus.com') {
  currentUrl[2] = 'sdmapi.vertexplus.com'
}
if (currentUrl[2] == 'welcomebeta.vertexplus.com') {
  currentUrl[2] = 'sdmapibeta.vertexplus.com'
}
if (currentUrl[2] == 'welcome.vertexplus.com') {
  currentUrl[2] = 'syncapi.vertexplus.com'
}


if(window.location.href.includes('localhost')){
  currentUrl[2] = "sdmapibeta.vertexplus.com";
}
const apiUrl = currentUrl[0] + '//' + currentUrl[2] + '/';

export const planworkConfig = {
  baseUrl: apiUrl,
  // baseUrl: 'http://planwork.vertexplus.com/',
  // baseUrl: 'http://planwork03.vertexplus.com/',
  // baseUrl: 'http://sdmapi.vertexplus.com/',
  // baseUrl: 'http://sdmapibeta.vertexplus.com/',
  // baseUrl: 'https://syncapi.vertexplus.com/',
  layout: {
    sidebar: 'show', // 'show', 'none'
    toolbar: 'show', // 'show', 'none'
    footer: 'show', // 'show', 'none'
  },
  colorClasses: {
    toolbar: 'mat-white-500-bg',
    navbar: 'mat-fuse-dark-700-bg',
    footer: 'mat-fuse-dark-900-bg'
  },
  customScrollbars: true,
  routerAnimation: 'fadeIn' // fadeIn, slideUp, slideDown, slideRight, slideLeft, none
}
