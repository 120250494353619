import { T } from '@angular/cdk/keycodes';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonModalComponent } from 'app/common-modal/common-modal.component';
import { planworkConfig } from 'app/planwork.config';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class DropdownService {
  apiUrl: any;
  baseUrl: any;
  customerSegmentUrl: any;
  IndustryTypeUrl: any;
  OrginisationTypeUrl: any;
  ReferenceModeUrl: any;
  CurrencyUrl: any;
  NumberSystemUrl: any;
  ClientListUrl: any;
  CountryDropDownUrl: any;
  ClientDepartmentUrl: any;
  DurationUnitUrl: any;
  DepartmentDropDownListUrl: any;
  KeySkillListUrl: any;
  DesignationUrl: any;
  // public httpOptions: any;
  KeySkillDropDownUrl: any;
  LeadCategoryUrl: any;
  KeyFeatureUrl: any;
  LeadTypeUrl: any;
  PriorityListUrl: any;
  QualificationUrl: any;
  DocumentTypeUrl: any;
  SkillCategoryUrl: any;
  AccountManagerUrl: any;
  ServiceManagerUrl: any;
  DeliveryManagerUrl: any;
  ServiceAuditorUrl: any;
  ProfitCenterUrl: any;
  TaxTypeUrl: any;
  ModeOfPOUrl: any;
  StateUrl: any;
  CityUrl: any;
  DurationUnitUrl2: any;
  CompanyBillingUrl: any;
  PORaisedByUrl: any;
  PaymentMilestoneUnitUrl: any;
  PaymentTypeUrl: any;
  BankAccountTypeUrl: any;
  BankListUrl: any;
  YearListUrl: any;
  YearDropDownListUrl: any;
  ShiftDropDownListUrl: any;
  NationalityDropDownListUrl: any;
  OccupationDropDownListUrl: string;
  EmployeeCatDropDownListUrl: string;
  MonthDropDownListUrl: string;
  CompanyDesignationListUrl: string;
  LevelListUrl: string;
  CadreDropDownListUrl: string;
  GetCompanyShiftListUrl: string;
  GetCompanyDepartmentList: string;
  GetCompanyDepartmentListUrl: string;
  EmployeeCostCenterDropDownListsUrl: string;
  IndustryTypeDropDownListUrl: string;
  BackgroundInfoDropDownListUrl: string;
  CompanyBillingListUrl: string;
  ReportingAuthorityDropDownListUrl: string;
  UserRoleDropDownListUrl: string;
  SBHUrlDropDownListUrl:string;
  exceptionText = 'There are some exception error, Please contact to support team.';
  dialogRef: any;
  OtherAvtivityDropDownListUrl: string;
  constructor(
    private http: HttpClient,public spinner: NgxSpinnerService,public dialog: MatDialog
  ) {

    this.apiUrl = planworkConfig.baseUrl;
    this.customerSegmentUrl = this.apiUrl + 'ClientCore/api/Lead/CustomerSegmentDropDownLists';
    this.IndustryTypeUrl = this.apiUrl + 'GlobalCore/api/Configuration/IndustryTypeDropDownList';
    this.OrginisationTypeUrl = this.apiUrl + 'CompanyCore/api/Service/OrganisationTypeList';
    this.ReferenceModeUrl = this.apiUrl + 'ClientCore/api/Client/ReferenceModeDropDownList';
    this.CurrencyUrl = this.apiUrl + 'GlobalCore/api/Configuration/CurrencyDropDownLists';
    this.NumberSystemUrl = this.apiUrl + 'GlobalCore/api/Configuration/NumberSystemDropDownList';
    this.ClientListUrl = this.apiUrl + 'ClientCore/api/Lead/CommonClientDropDownList';
    this.ClientDepartmentUrl = this.apiUrl + 'CompanyCore/api/Campaign/GetClientDepartmentLists';
    this.CountryDropDownUrl = this.apiUrl + 'GlobalCore/api/Configuration/CountryDropDownLists';
    this.KeySkillDropDownUrl = this.apiUrl + 'EmployeeCore/api/Lead/KeySkillDropDownLists';
    this.DurationUnitUrl = this.apiUrl + 'GlobalCore/api/Campaign/DurationUnitDropDownLists';
    this.DepartmentDropDownListUrl = this.apiUrl + 'CompanyCore/api/CompanyDetail/SkillCategoryDepartmentDropDownList';
    this.KeySkillListUrl = this.apiUrl + 'EmployeeCore/api/Employee/KeySkillDropDownList';
    this.DesignationUrl = this.apiUrl + 'CompanyCore/api/Service/DesignationDropDownLists';
    this.LeadCategoryUrl = this.apiUrl + 'CompanyCore/api/Lead/LeadCategoryDropDownLists?BusinessProcessId=';
    this.KeyFeatureUrl = this.apiUrl + 'lead/api/CampaignEnquiry/KeyFeatureDropDownLists?BusinessProcessId=';
    this.LeadTypeUrl = this.apiUrl + 'CompanyCore/api/Lead/LeadTypeDropDownLists?BusinessProcessId=';
    this.ServiceManagerUrl = this.apiUrl + 'CompanyCore/api/Service/ServiceManagerList?ClientId=';
    this.DeliveryManagerUrl = this.apiUrl + 'CompanyCore/api/Service/DeliveryManagerList?ClientId=';
    this.ServiceAuditorUrl = this.apiUrl + 'CompanyCore/api/Service/ServiceAuditorList?ClientId=';
    this.ProfitCenterUrl = this.apiUrl + 'CompanyCore/api/Service/ProfitCenterList?ClientId=';
    this.ModeOfPOUrl = this.apiUrl + 'CompanyCore/api/Service/ModeOfPOList?ClientId=';
    this.PriorityListUrl = this.apiUrl + 'EmployeeCore/api/Lead/PriorityStatusDropDownLists';
    this.QualificationUrl = this.apiUrl + 'EmployeeCore/api/Lead/QualificationDropwownList';
    this.DocumentTypeUrl = this.apiUrl + 'EmployeeCore/api/Lead/DocumentTypeDropDownList';
    this.SkillCategoryUrl = this.apiUrl + 'EmployeeCore/api/Lead/SkillCategoryDropDownLists';
    this.AccountManagerUrl = this.apiUrl + 'CompanyCore/api/Service/AccountManagerList';
    this.StateUrl = this.apiUrl + 'GlobalCore/api/Configuration/StateDropDown?CountryId=';
    this.CityUrl = this.apiUrl + 'GlobalCore/api/Configuration/CityDropDown?StateId=';
    this.TaxTypeUrl = this.apiUrl + 'CompanyCore/api/Service/TaxTypeList';
    this.CompanyBillingUrl = this.apiUrl + 'CompanyCore/api/Service/CompanyBillingList?ClientId=';
    this.PaymentMilestoneUnitUrl = this.apiUrl + 'CompanyCore/api/Service/PaymentMilestoneUnitList';
    this.PORaisedByUrl = this.apiUrl + 'CompanyCore/api/Service/PORaisedByList';
    this.DurationUnitUrl2 = this.apiUrl + 'CompanyCore/api/Service/DurationUnitList';
    this.PaymentTypeUrl = this.apiUrl + 'EmployeeCore/api/Lead/PaymentTypeList';
    this.BankAccountTypeUrl = this.apiUrl + 'EmployeeCore/api/Employee/BankAccountTypeList';
    this.BankListUrl = this.apiUrl + 'EmployeeCore/api/Employee/BankList';
    this.YearListUrl = this.apiUrl + 'EmployeeCore/api/Employee/YearList';
    this.YearDropDownListUrl = this.apiUrl + 'EmployeeCore/api/Employee/YearDropdownList';
    this.ShiftDropDownListUrl = this.apiUrl + 'CompanyCore/api/CompanyDetail/GetCompanyShiftLists';
    this.NationalityDropDownListUrl = this.apiUrl + "GlobalCore/api/Configuration/NationalityDropDownList";
    this.OccupationDropDownListUrl = this.apiUrl + "EmployeeCore/api/Employee/OccupationDropDownList";
    this.EmployeeCatDropDownListUrl = this.apiUrl + "EmployeeCore/api/Employee/EmployeeCategoryDropDownList";
    this.MonthDropDownListUrl = this.apiUrl + "EmployeeCore/api/Employee/MonthDropDownList";
    this.CompanyDesignationListUrl = this.apiUrl + "CompanyCore/api/CompanyDetail/CompanyDesignationLists";
    this.LevelListUrl = this.apiUrl + "EmployeeCore/api/Employee/LevelListNew";
    this.BackgroundInfoDropDownListUrl = this.apiUrl + "EmployeeCore/api/Employee/BackgroundInfoDropDownList";
    this.CadreDropDownListUrl = this.apiUrl + "EmployeeCore/api/Employee/CadreDropDownList";
     this.GetCompanyShiftListUrl = this.apiUrl + "CompanyCore/api/CompanyDetail/GetCompanyShiftLists";
    this.GetCompanyDepartmentListUrl = this.apiUrl + "CompanyCore/api/CompanyDetail/GetCompanyDepartmentLists";
    this.EmployeeCostCenterDropDownListsUrl = this.apiUrl + "CompanyCore/api/CompanyDetail/EmployeeCostCenterDropDownLists";
    this.IndustryTypeDropDownListUrl = this.apiUrl + "GlobalCore/api/Configuration/IndustryTypeDropDownList";
    this.CompanyBillingListUrl = this.apiUrl + "CompanyCore/api/Service/CompanyBillingList";
    this.ReportingAuthorityDropDownListUrl = this.apiUrl + "EmployeeCore/api/Employee/ReportingAuthorityDropDownList";
    this.UserRoleDropDownListUrl = this.apiUrl + "EmployeeCore/api/Employee/UserRoleDropDownListNew";
    this.SBHUrlDropDownListUrl = this.apiUrl + "CompanyCore/api/CompanyDetail/ActiveCostCenterCategoryList";
    this.OtherAvtivityDropDownListUrl = this.apiUrl + "Task/api/TaskAssignments/OtherActivityDropDownList";

  }

  private getHttpOptions() {
    const currentToken = localStorage.getItem('token');
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + currentToken
      })
    };
  }
  //CustomerSegmentDropDownList
  customerSegmentDropDown(payload): Observable<any> {
    return this.http.post(this.customerSegmentUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }

  // OrginisationTypeDropDown
  OrginisationTypeDropDown(payload): Observable<any> {
    return this.http.post(this.OrginisationTypeUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  // IndustryTypeDropDown
  IndustryTypeDropDown(payload): Observable<any> {
    return this.http.post(this.IndustryTypeUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  // ReferenceModeDropDownList
  ReferenceModeDropDown(payload): Observable<any> {
    return this.http.post(this.ReferenceModeUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  // CurrencyDropDownList
  CurrencyDropDownList(payload): Observable<any> {
    return this.http.post(this.CurrencyUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  // NumberSystemDropDownList
  NumberSystemDropDownList(payload): Observable<any> {
    return this.http.post(this.NumberSystemUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  // ClientListDropDownList
  ClientListDropDownList(payload): Observable<any> {
    return this.http.post(this.ClientListUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  // GetClientDepartmentList
  GetClientDepartmentList(payload): Observable<any> {
    return this.http.post(this.ClientDepartmentUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  // CountryDropDownlist
  CountryDropDownlist(payload): Observable<any> {
    return this.http.post(this.CountryDropDownUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  // KeySkillDropDownList
  KeySkillDropDownList(payload): Observable<any> {
    return this.http.post(this.KeySkillDropDownUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  // DurationUnitDropDownList
  DurationUnitDropDownList(payload): Observable<any> {
    return this.http.post(this.DurationUnitUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));

  }
  // DepartmentDropDownList
  DepartmentDropDownList(payload): Observable<any> {
    return this.http.post(this.DepartmentDropDownListUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  // KeySkillList
  KeySkillList(payload): Observable<any> {
    return this.http.post(this.KeySkillListUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  // DesignationList
  DesignationList(payload): Observable<any> {
    return this.http.post(this.DesignationUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  //LeadCategoryUrl
  LeadCategoryDropDownList(id, payload): Observable<any> {
    return this.http.post(this.LeadCategoryUrl + id, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  //KeyFeatureUrl
  KeyFeatureDropDownList(id, payload): Observable<any> {
    return this.http.post(this.KeyFeatureUrl + id, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  //LeadTypeUrl
  LeadTypeDropDownList(id, Lid, payload): Observable<any> {
    return this.http.post(this.LeadTypeUrl + id + '&LeadCategoryId=' + Lid, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  //PriorityStatusList
  PriorityStatusList(payload): Observable<any> {
    return this.http.post(this.PriorityListUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  //QualificationList
  QualificationList(payload): Observable<any> {
    return this.http.post(this.QualificationUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  //DocumentTypeList
  DocumentTypeList(payload): Observable<any> {
    return this.http.post(this.DocumentTypeUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  //SkillCategoryList
  SkillCategoryList(payload): Observable<any> {
    return this.http.post(this.SkillCategoryUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  AccountManagerDropDown(payload): Observable<any> {
    return this.http.post(this.AccountManagerUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  ServiceManagerDropDown(id, payload): Observable<any> {
    return this.http.post(this.ServiceManagerUrl + id, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  DeliveryManagerDropDown(id, payload): Observable<any> {
    return this.http.post(this.DeliveryManagerUrl + id, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  ServiceAuditorDropDown(id, payload): Observable<any> {
    return this.http.post(this.ServiceAuditorUrl + id, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  ProfitCenterDropDown(id, payload): Observable<any> {
    return this.http.post(this.ProfitCenterUrl + id, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  ModeOfPODropDown(id, payload): Observable<any> {
    return this.http.post(this.ModeOfPOUrl + id, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  StateDropDown(id, payload): Observable<any> {
    return this.http.post(this.StateUrl + id, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  CityDropDown(id, payload): Observable<any> {
    return this.http.post(this.CityUrl + id, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  TaxTypeDropDown(payload): Observable<any> {
    return this.http.post(this.TaxTypeUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  CompanyBillingDropDown(id, payload): Observable<any> {
    return this.http.post(this.CompanyBillingUrl + id, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  DurationUnitDropDown(payload): Observable<any> {
    return this.http.post(this.DurationUnitUrl2, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  PaymentMilestoneUnitDropDown(payload): Observable<any> {
    return this.http.post(this.PaymentMilestoneUnitUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  PORaisedByDropDown(payload): Observable<any> {
    return this.http.post(this.PORaisedByUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  PaymentTypeDropDown(payload): Observable<any> {
    return this.http.post(this.PaymentTypeUrl, payload, this.getHttpOptions())

    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));

  }
  BankAccountTypeList(payload): Observable<any> {
    return this.http.post(this.BankAccountTypeUrl, payload, this.getHttpOptions())
     .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  BankListfunc(payload): Observable<any> {
    return this.http.post(this.BankListUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));

  }
  YearList(payload): Observable<any> {
    return this.http.post(this.YearListUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  YearDropDownList(payload): Observable<any> {
    return this.http.post(this.YearDropDownListUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  ShiftDropDownList(payload): Observable<any> {
    return this.http.post(this.ShiftDropDownListUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  //Nationality Dropdownlist
  NationalityDropDownList(payload): Observable<any> {
    return this.http.post(this.NationalityDropDownListUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  OccupationDropDownList(payload): Observable<any> {
    return this.http.post(this.OccupationDropDownListUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  EmployeecatDropDownList(payload): Observable<any> {
    return this.http.post(this.EmployeeCatDropDownListUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  MonthDropDownList(payload): Observable<any> {
    return this.http.post(this.MonthDropDownListUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  CompanyDesignationList(payload): Observable<any> {
    return this.http.post(this.CompanyDesignationListUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  LevelList(payload): Observable<any> {
    return this.http.post(this.LevelListUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
  cadreddList(payload): Observable<any>{
  return this.http.post(this.CadreDropDownListUrl, payload, this.getHttpOptions())
  .pipe(catchError((e: any) => { this.ShowError()
    return throwError(this.exceptionText);}));
  }
  GetCompanyShiftList(payload): Observable<any>{
  return this.http.post(this.GetCompanyShiftListUrl, payload, this.getHttpOptions())
  .pipe(catchError((e: any) => { this.ShowError()
    return throwError(this.exceptionText);}));
  }
  GetCompanyDepartmentListdd(payload): Observable<any>{
  return this.http.post(this.GetCompanyDepartmentListUrl, payload, this.getHttpOptions())
  .pipe(catchError((e: any) => { this.ShowError()
    return throwError(this.exceptionText);}));
  }
  EmployeeCostCenterDropDownList(payload): Observable<any>{
  return this.http.post(this.EmployeeCostCenterDropDownListsUrl, payload, this.getHttpOptions())
  .pipe(catchError((e: any) => { this.ShowError()
    return throwError(this.exceptionText);}));
  }
  IndustryTypeDropDownList(payload): Observable<any>{
  return this.http.post(this.IndustryTypeDropDownListUrl, payload, this.getHttpOptions())
  .pipe(catchError((e: any) => { this.ShowError()
    return throwError(this.exceptionText);}));
  }
  BackgroundInfoDropDownList(payload): Observable<any>{
  return this.http.post(this.BackgroundInfoDropDownListUrl, payload, this.getHttpOptions())
  .pipe(catchError((e: any) => { this.ShowError()
    return throwError(this.exceptionText);}));
  }
  CompanyBillingList(payload): Observable<any>{
  return this.http.post(this.CompanyBillingListUrl, payload, this.getHttpOptions())
  .pipe(catchError((e: any) => { this.ShowError()
    return throwError(this.exceptionText);}));
  }
  ReportingAuthorityDropDownList(payload): Observable<any>{
  return this.http.post(this.ReportingAuthorityDropDownListUrl, payload, this.getHttpOptions())
  .pipe(catchError((e: any) => { this.ShowError()
    return throwError(this.exceptionText);}));
  }
  UserRoleDropDownList(payload): Observable<any>{
  return this.http.post(this.UserRoleDropDownListUrl, payload, this.getHttpOptions())
  .pipe(catchError((e: any) => { this.ShowError()
    return throwError(this.exceptionText);}));
  }
  SBHDropDownList(payload): Observable<any>{
    return this.http.post(this.SBHUrlDropDownListUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
    }

  handleError(error: HttpErrorResponse) {
    this.spinner.hide();
    this.dialogRef = this.dialog.open(CommonModalComponent, {
        width: '300px',
        disableClose: true,
        autoFocus: false,
        panelClass: 'statusClass',
        data: { action: 'delete', statusText: this.exceptionText }
      });
  }
  ShowError(){
    this.spinner.hide();
    this.dialogRef = this.dialog.open(CommonModalComponent, {
        width: '300px',
        disableClose: true,
        autoFocus: false,
        panelClass: 'statusClass',
        data: { action: 'delete', statusText: this.exceptionText }
      });
  }

  OtherActivityDropDownList(payload): Observable<any> {
    return this.http.post(this.OtherAvtivityDropDownListUrl, payload, this.getHttpOptions())
    .pipe(catchError((e: any) => { this.ShowError()
      return throwError(this.exceptionText);}));
  }
}
