import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { planworkConfig } from 'app/planwork.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import axios from 'axios';

import { NgxSpinnerService } from 'ngx-spinner';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { CommonModalComponent } from '../common-modal/common-modal.component';

@Injectable({
  providedIn: 'root'
})

export class ServiceService {
  config: any;
  SpinnerTimeout = 500;
  ClientIP: any;
  IPAddress: any;
  apiUrl: any;
  private baseUrl: any;
  private baseloginUrl: any;
  private baseKeyUrl: any;
  private baseUrlClient: any;
  private baseUrlEmployee: any;
  private userRole: any;
  private baseDashboardUrl: any;
  private baseUrlService: any;
  private baseLeadForFC: any;
  private baseServiceURL: any;
  private baseBillingURL: any;
  private baseInvoiceURL: any;
  private baseEmployeeURL: any;
  private globalBaseUrl: any;
  private EmployeeBaseUrl: any;
  private ClientBaseUrl: any;
  private companyBaseUrl: any;
  private BillingBaseUrl: any;
  private companyCoreUrl: any;
  private LeadBaseUrl: any;
  private CampaignBaseUrl: any;
  private baseUrlAllocate: any;
  private baseUrlStaffing: any;
  dialogRef: any;
  exceptionText = 'There are some exception error, Please contact to support team.';
  dmsDocumentUrl = 'http://sdmapibeta.vertexplus.com/DMS/api/';
  // dmsDocumentUrl = 'https://syncapi.vertexplus.com/DMS/api/';
  globalBaseUrl2: string;
  baseassetpurchaseUrl: any;
  baseassetpurchaseUrl2: any;
  baseassetpurchaseUrl3: any;
  constructor(
    private http: HttpClient, private spinner: NgxSpinnerService,
    public dialog: MatDialog
  ) {
    // this.http.get<any>('https://jsonip.com')
    // .subscribe(data => {
    //   const serverIp = data.ip;
    //   const privateIP = this.ClientIP;
    //   const IP = serverIp + ':' + privateIP;
    //   this.IPAddress = IP;
    // });
    this.apiUrl = planworkConfig.baseUrl;


    this.baseassetpurchaseUrl = this.apiUrl + 'Purchases/api/Rfq/';
    this.baseassetpurchaseUrl2 = this.apiUrl + 'Purchases/api/RMRfq/';
    this.baseassetpurchaseUrl3 = this.apiUrl + 'Purchases/api/WorkOrder/';
    this.baseloginUrl = this.apiUrl + 'EmployeeCore/api/';
    this.baseDashboardUrl = this.apiUrl + 'GlobalCore/api/Dashboard/';
    this.baseUrl = this.apiUrl + 'GlobalCore/api/Configuration/';
    this.baseUrlClient = this.apiUrl + 'ClientCore/api/Client/';
    this.baseUrlService = this.apiUrl + 'Service/api/Service/';
    //this.baseUrlEmployee = this.apiUrl + 'core/api/Employee/';
    this.baseUrlEmployee = this.apiUrl + 'EmployeeCore/api/Employee/';

    this.userRole = this.apiUrl + 'EmployeeCore/api/RolePermission/';
    this.baseKeyUrl = this.apiUrl + 'lead/api/Configuration/';
    this.baseLeadForFC = this.apiUrl + 'lead/api/LeadForFC/';
    this.baseServiceURL = this.apiUrl + 'Service/api/Service/';
    this.baseBillingURL = this.apiUrl + 'Billing/api/ServiceBilling/';
    this.baseInvoiceURL = this.apiUrl + 'Billing/api/Invoices/';

    this.baseEmployeeURL = this.apiUrl + 'EmployeeCore/api/Employee/';
    // shivram new service params --------
    this.globalBaseUrl = 'GlobalCore/api/Configuration/';
    this.globalBaseUrl2 = this.apiUrl+ 'GlobalCore/api/Configuration/';
    this.EmployeeBaseUrl = 'EmployeeCore/api/Employee/';
    this.ClientBaseUrl = 'ClientCore/api/Client/';
    this.companyBaseUrl = 'CompanyCore/api/CompanyDetail/';
    this.companyCoreUrl = this.apiUrl + 'CompanyCore/api/CompanyDetail/';
    this.LeadBaseUrl = 'lead/api/Configuration/';
    this.BillingBaseUrl = 'Billing/api/Configuration/';
    this.CampaignBaseUrl = 'campaign/api/Configuration/';
    this.baseUrlAllocate = this.apiUrl + 'Task/api/Task/'
    this.baseUrlStaffing = this.apiUrl + 'Task/api/StaffingTask/'
    // shivram new service params --------



  }

  async asyncPostService(saveData: any, currentUrl: any, baseUrlType: any) {
    this.spinner.show();
    let finalUrl = ''
    switch (baseUrlType) {
      case 'GlobalCore':
        finalUrl = this.apiUrl + this.globalBaseUrl
        break;
      case 'EmployeeCore':
        finalUrl = this.apiUrl + this.EmployeeBaseUrl
        break;
      case 'ClientCore':
        finalUrl = this.apiUrl + this.ClientBaseUrl
        break;
      case 'CompanyCore':
        finalUrl = this.apiUrl + this.companyBaseUrl
        break;
      case 'Lead':
        finalUrl = this.apiUrl + this.LeadBaseUrl
        break;
      case 'Billing':
        finalUrl = this.apiUrl + this.BillingBaseUrl
        break;
      case 'Campaign':
        finalUrl = this.apiUrl + this.CampaignBaseUrl
        break;

    }
    finalUrl = finalUrl + currentUrl



    let response = { message: '' };
    try {
      response = await axios.post(finalUrl, saveData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      });
    } catch (e) {
      response.message = e.message
    }
    this.spinner.hide();
    return response
  }

  async subscriber() {
    //return await axios.get('https://jsonip.com')
  }
  async loginService(saveData: any, currentUrl: any) {
    this.spinner.show();
    const subscriber = await this.subscriber()
    // && subscriber.data.ip
    //if () {
    const response = await axios.post(this.baseloginUrl + currentUrl, saveData);
    Object.assign(response);
    this.spinner.hide();
    return response
    //}

  }
  vendorPurchaseRequest(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseassetpurchaseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  vendorPurchaseRequest2(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseassetpurchaseUrl2 + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  vendorPurchaseRequest3(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseassetpurchaseUrl3 + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  loginServicetoken(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseloginUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

  LogoutService(saveData: any, currentUrl: any): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(this.baseloginUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

  DashboardService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseDashboardUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

  URLCommon(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.apiUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

  ServiceService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseUrlService + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

  PostService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseServiceURL + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      }).pipe(
        catchError(err => {
          this.spinner.hide();
          this.dialogRef = this.dialog.open(CommonModalComponent, {
            width: '300px',
            disableClose: true,
            autoFocus: false,
            panelClass: 'statusClass',
            data: { action: 'delete', statusText: this.exceptionText }
          });
          return throwError(err);
        })
      )
  }

  //Billing
  billingPostService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseBillingURL + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      }).pipe(
        catchError(err => {
          this.spinner.hide();
          this.dialogRef = this.dialog.open(CommonModalComponent, {
            width: '300px',
            disableClose: true,
            autoFocus: false,
            panelClass: 'statusClass',
            data: { action: 'delete', statusText: this.exceptionText }
          });
          return throwError(err);
        })
      )
  }
  //Invoice
  invoicePostService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseInvoiceURL + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      }).pipe(
        catchError(err => {
          this.spinner.hide();
          this.dialogRef = this.dialog.open(CommonModalComponent, {
            width: '300px',
            disableClose: true,
            autoFocus: false,
            panelClass: 'statusClass',
            data: { action: 'delete', statusText: this.exceptionText }
          });
          return throwError(err);
        })
      )
  }

  LeadForFCService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseLeadForFC + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

  postService(saveData: any, currentUrl: any): Observable<any> {



    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }


  employeeCoreService(saveData: any, currentUrl: any): Observable<any> {



    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseEmployeeURL + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }


  companyCoreService(saveData: any, currentUrl: any): Observable<any> {

    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.companyCoreUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  globalBase(saveData: any, currentUrl: any): Observable<any> {

    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.globalBaseUrl2 + currentUrl, saveData,
      {
        headers: { 'Authorization': 'Bearer ' + currentToken},
        observe: 'response'
      });
  }


  async companyCorepostService(saveData: any, currentUrl: any) {

    this.spinner.show();
    let response = { message: '' };
    try {
      response = await axios.post(this.companyCoreUrl + currentUrl, saveData,
        {
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        });
    } catch (e) {
      response.message = e.message
    }
    this.spinner.hide();
    return response
  }


  async newpostService(saveData: any, currentUrl: any) {

    this.spinner.show();
    let response = { message: '' };
    try {
      response = await axios.post(this.baseUrl + currentUrl, saveData,
        {
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        });
    } catch (e) {
      response.message = e.message
    }
    this.spinner.hide();
    return response
  }

  async newEditpostService(saveData: any, currentUrl: any) {
    this.spinner.show();
    let response = { message: '' };
    try {
      response = await axios.post(this.baseKeyUrl + currentUrl, saveData,
        {
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        });
    } catch (e) {
      response.message = e.message
    }
    this.spinner.hide();
    return response
  }
  async verynewpostService(saveData: any, currentUrl: any) {
    this.spinner.show();
    const response = await axios.post(this.baseUrlClient + currentUrl, saveData,
      {
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
      });
    this.spinner.hide();
    return response
  }



  postKey(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseKeyUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

  postClient(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseUrlClient + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

  VerifyPost(saveData: any, currentUrl: any): Observable<any> {
    // const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: 'Bearer ' + null
    });
    return this.http.post(this.baseUrlEmployee + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  postEmployee(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseUrlEmployee + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

  userRolePermission(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.userRole + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  Allocate(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseUrlAllocate + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  staffingPostService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseUrlStaffing + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  getDocument(saveData: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken,
      VTApiKey: 'VTPL_DMS01_03_IbLKyiuhyKBLUJSgipwlactuyI87UJJNBRTFSPLIKEO87656#$%#@@.E'
    });
    return this.http.post(`${this.dmsDocumentUrl}PMS/DocumentGetByIdForSDM`, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      }).pipe(
        catchError(err => {
          this.spinner.hide();
          this.dialogRef = this.dialog.open(CommonModalComponent, {
            width: '300px',
            disableClose: true,
            autoFocus: false,
            panelClass: 'statusClass',
            data: { action: 'delete', statusText: this.exceptionText }
          });
          return throwError(err);
        })
      )
  }
}

